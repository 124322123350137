const fab = (() => {
  function toggleFab() {
    $(this)
      .closest('.fab')
      .toggleClass('expanded');

    if ($(this).hasClass('expanded')) {
      $(this).attr('aria-expanded', 'true');
    } else {
      $(this).attr('aria-expanded', 'false');
    }
  }
  const init = () => {
    if (!$('.fab').length) {
      return;
    }

    $('.fab__toggle').attr('aria-expanded', 'false');
    $('.fab__toggle').click(toggleFab);
  };

  return {
    init,
  };
})();

export default fab;
