import '@babel/polyfill';
import 'object-fit-images';
import 'focus-visible';
import accordion from './object/accordion';
import inlineVideo from './object/video';
import column from './object/column';
import instagramLoader from './object/instafeed';
import twitterLoader from './object/twitter';
import header from './object/header';
import fab from './object/fab';
import tableMobile from './object/table';
import sectionNav from './object/section-nav';
import programFinder from './object/program-finder';
import bgVid from './object/bg-vid';
import startedTabs from './object/started-tabs';
import studentJourney from './object/student-journey';
import pathwayAccordion from './object/pathway-accordion';
import homepagePathways from './object/homepage-pathways';
import pathwayCarousel from './object/pathway-carousel';
import catalogProgram from './object/catalog-program';
import catalog from './object/catalog';
import carousel from './object/carousel';

window.$ = require('jquery');
window.jQuery = require('jquery');
const objectFitImages = require('object-fit-images');

$(() => {
  accordion.init();
  inlineVideo.init();
  instagramLoader.init();
  twitterLoader.init();
  header.init();
  fab.init();
  tableMobile.init();
  sectionNav.init();
  programFinder.init();
  startedTabs.init();
  bgVid.init();
  studentJourney.init();
  pathwayAccordion.init();
  homepagePathways.init();
  pathwayCarousel.init();
  catalogProgram.init();
  catalog.init();
  carousel.init();
  objectFitImages();

  // Run after instagram and others have loaded
  $(window).on('load resize', () => {
    setTimeout(() => {
      column.init();
    }, 100);
  });
});
