const sectionNav = (() => {
  function toggleSectionNav() {
    $(this).toggleClass('expanded');
    $(this)
      .next()
      .slideToggle();

    if ($(this).hasClass('expanded')) {
      $(this).attr('aria-expanded', 'true');
    } else {
      $(this).attr('aria-expanded', 'false');
    }
  }

  function closeSectionNav() {
    $('.section-nav__toggle')
      .removeClass('expanded')
      .attr('aria-expanded', 'false');

    $('.section-nav__list').slideUp();
  }

  function checkImage() {
    if (!$('.pattern-hero img').length) {
      $('.pattern-hero').addClass('no-image');
    }
  }

  function setContainerHeight() {
    if ($(window).width() < 1024) {
      $('.container').css({
        'min-height': '',
      });
    } else {
      let height = $('.section-nav').outerHeight() - (($('.pattern-hero img').height() - 60 ) || 0);
      $('.container').css({
        'min-height': height,
      });
    }
  }

  const init = () => {
    if (!$('.section-nav').length) {
      return;
    }
    $(window).on('load', checkImage);

    $('.section-nav__toggle').attr('aria-expanded', 'false');

    $('.section-nav__toggle').click(toggleSectionNav);

    $(window).on('resize', setContainerHeight);
    setContainerHeight();

    if ($(window).width() < 1024) {
      $('.section-nav').on('mouseleave touchleave', closeSectionNav);

      $('body').on('touchmove', closeSectionNav);

      $('.section-nav').on('touchmove', e => {
        e.stopPropagation();
      });
    }
  };

  return {
    init,
  };
})();

export default sectionNav;
