/* eslint-disable func-names */
/**
 * Header functionality
 * @param {*} $
 * @returns {init}
 */

const header = (() => {
  // Open the main menu
  function toggleMenu() {
    $('.header__open-menu').toggleClass('js-expanded');
    $('.menu-panel').slideToggle(300);
    $('html').toggleClass('open-menu');

    if (
      $('.alert__content').length > 0 &&
      !$('body').hasClass('stickyHeader')
    ) {
      $('.menu-panel').css(
        'top',
        $('.alert__content').outerHeight() +
          $('header').outerHeight() -
          $(window).scrollTop(),
      );
    } else {
      $('.menu-panel').css('top', '');
    }

    if ($('.header__open-menu').hasClass('js-expanded')) {
      $('.header__open-menu').attr('aria-expanded', 'true');
      $('.header__open-menu').text('Close');
    } else {
      $('.header__open-menu').attr('aria-expanded', 'false');
      $('.header__open-menu').text('Menu');
    }

    if ($('.header__open-search').hasClass('js-expanded')) {
      $('.header__open-search')
        .removeClass('js-expanded')
        .attr('aria-expanded', 'false');

      $('.header__search__wrapper').slideUp(300);
    }
  }

  function toggleSearch(e) {
    $('.header__open-search').toggleClass('js-expanded');
    $('.header__open-search')
      .next()
      .slideToggle(300);

    $('body').toggleClass('open-search');
    if (
      $('.alert__content').length > 0 &&
      !$('body').hasClass('stickyHeader') &&
      $(window).width() < 1100
    ) {
      $('.header__search__wrapper').css(
        'top',
        $('.alert__content').outerHeight() +
          $('header').outerHeight() -
          $(window).scrollTop(),
      );
    } else {
      $('.header__search__wrapper').css('top', '');
    }

    if ($('.header__open-search').hasClass('js-expanded')) {
      $('.header__open-search').attr('aria-expanded', 'true');
      $('#site-search').focus();
    } else {
      $('.header__open-search').attr('aria-expanded', 'false');
    }

    if ($('.header__open-menu').hasClass('js-expanded')) {
      $('.header__open-menu')
        .removeClass('js-expanded')
        .attr('aria-expanded', 'false')
        .text('Menu');

      $('.menu-panel').slideUp(300);
    }

    e.preventDefault();
  }

  function toggleSubMenu() {
    $(this).toggleClass('js-expanded');
    $(this)
      .next()
      .slideToggle(300);

    if ($(this).hasClass('js-expanded')) {
      $(this).attr('aria-expanded', 'true');
    } else {
      $(this).attr('aria-expanded', 'false');
    }
  }

  function focusWithin() {
    $('.main-menu__item').removeClass('focus-within');
    $(this)
      .closest('.main-menu__item')
      .addClass('focus-within');
  }

  function removeFocusWithin() {
    $('.main-menu__item').removeClass('focus-within');
  }

  function searchTabloopSmall(e) {
    if ((e.keyCode || e.which) === 9 && !e.shiftKey) {
      $('.header__search > .header__open-search').focus();
      e.preventDefault();
    }
  }
  function searchTabloopLarge(e) {
    if ((e.keyCode || e.which) === 9 && !e.shiftKey) {
      $('.search__form input[type="search"]').focus();
      e.preventDefault();
    }
  }

  function searchTabloopBackward(e) {
    if ((e.keyCode || e.which) === 9 && e.shiftKey) {
      if ($('.search__form .header__open-search').is(':visible')) {
        $('.search__form .header__open-search').focus();
      } else {
        $('.header__search > .header__open-search').focus();
      }
      e.preventDefault();
    }
  }

  function menuTabloop(e) {
    if ((e.keyCode || e.which) === 9 && !e.shiftKey && $(this).is('.menu-panel a:visible:last')) {
      $('.header__open-menu').focus();
      e.preventDefault();
    }
  }

  function stickyHeader() {
    if ($('.alert__content').length > 0) {
      const alertHeight = $('.alert__content').outerHeight();
      let lastScrollTop = 0;
      $(window).scroll(() => {
        const st = window.pageYOffset || document.documentElement.scrollTop;
        if (st > lastScrollTop) {
          // Scroll Down code
          if ($(window).scrollTop() > alertHeight + $('header').outerHeight()) {
           // $('body').removeClass('stickyHeader');
          }
          $('body').addClass('scrollDown');
        } else {
          // Scroll Up code
          // eslint-disable-next-line no-lonely-if
          if ($(window).scrollTop() > alertHeight) {
            $('body').addClass('stickyHeader');
          } else {
            $('body').removeClass('stickyHeader');
          }
          $('body').removeClass('scrollDown');
        }
        lastScrollTop = st;
      });
    } else {
      let lastScrollTop = 0;
      $(window).scroll(() => {
        const st = window.pageYOffset || document.documentElement.scrollTop;
        if ($(window).scrollTop() > $('header').outerHeight()) {
          $('body').addClass('stickyHeader');
          if (st > lastScrollTop) {
            // Scroll Down code
            $('body').addClass('scrollDown');
          } else {
            // Scroll Up code

            $('body').removeClass('scrollDown');
          }
        } else {
          $('body').removeClass('stickyHeader');
        }
        lastScrollTop = st;
      });
    }
  }

  function hideMenu(e) {
    $('.header__open-menu').attr('aria-expanded', 'false');
    $('.header__open-menu').text('Menu');

    $('.header__open-menu').removeClass('js-expanded');
    $('.menu-panel').slideUp(300);
    $('html').removeClass('open-menu');
  }

  const init = () => {
    if (!$('header').length) {
      return;
    }
    $('.main-menu__item a').focus(focusWithin);

    $('.main-menu__item a').blur(removeFocusWithin);
    $('.main-menu__item').mouseout(removeFocusWithin);

    $('.header__open-menu').attr('aria-expanded', 'false');
    $('.header__open-menu').on('click', toggleMenu);

    $('.header__open-search').attr('aria-expanded', 'false');
    $('.header__open-search').on('click', toggleSearch);

    $(window).on('scroll', stickyHeader());

    $('.links-menu__toggle, .main-menu__toggle').attr('aria-expanded', 'false');
    $('.links-menu__toggle, .main-menu__toggle').on('click', toggleSubMenu);

    $('.search__form input[type="search"]').on(
      'keydown',
      searchTabloopBackward,
    );

    if ($(window).width() > 1100) {
      $('.search__form button').on('keydown', searchTabloopLarge);
    } else {
      $('.search__form input[type="submit"]').on('keydown', searchTabloopSmall);

      $('.menu-panel a').on('keydown', menuTabloop);

      $('body').on('click', hideMenu);
      $('header').on('click', function(e) {
        e.stopPropagation();
      });
    }
  };

  return {
    init,
  };
})();

export default header;
