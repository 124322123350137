/**
 * Ambient hero video interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if ambient video present
 */

const bgVid = (() => {
  function bgVidToggle(e) {
    if (e.type === 'click' || e.which === 13) {
      if (document.getElementById('home-hero-vid').paused) {
        document.getElementById('home-hero-vid').play();
      } else {
        document.getElementById('home-hero-vid').pause();
      }
    }
  }
  const init = () => {
    if (!$('.homepage-hero').length) {
      return;
    }

    if ($(window).width() < 1025) {
      $('.homepage-hero').attr('tabindex', '');
    }

    $('.homepage-hero').on('click keydown', bgVidToggle);
  };

  return {
    init,
  };
})();

export default bgVid;
