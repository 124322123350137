/**
 * Inline video play functionality
 * @param {*} $
 * @returns {init} Determine and run if inline video exists
 */

const inlineVideo = (() => {
  function vidInline(e) {
    e.preventDefault();

    $(this)
      .next()
      .addClass('js-show');
    $(this)
      .next()
      .find('video')[0]
      .play();
  }

  const init = () => {
    if (!$('.vid-inline').length) {
      return;
    }

    $('.vid-inline__cover').on('click keypress', vidInline);
  };

  return {
    init,
  };
})();

export default inlineVideo;
