const studentJourney = (() => {
  function studentJourneyToggle(e) {
    if (e.type === 'click' || e.which === 13) {

      const id = $(this).data('id');
      const panels = $(this).closest('.student-journey__panels');
      $('.student-journey__panels').removeClass(
        'panel-active-1 panel-active-2 panel-active-3',
      );
      $('.student-journey__panels').addClass(`panel-active-${id}`);
      $('.student-journey__button').removeClass('active');
      $('.student-journey__panel-wrapper').removeClass('active');

      panels
        .siblings()
        .find(`.student-journey__button[data-id=${id}]`)
        .addClass('active');
      panels
        .siblings()
        .find(`.student-journey__button[data-id=${id}]`)
        .parent()
        .addClass('active');
      $(this).addClass('active');
      $(this)
        .parent()
        .addClass('active');

      if ($(window).width() < 700) {
        const scrollpos = $('.student-journey__panel:visible')
          .offset().top;
          console.log(scrollpos);

        $('html, body').animate({scrollTop: scrollpos});
      }
    }
  }

  function heightInit() {
    let maxHeight = -1;

    $('.student-journey__panels.lg .student-journey__panel').each(function() {
      maxHeight = maxHeight > $(this).height() ? maxHeight : $(this).height();
    });

    $('.student-journey__panels.lg .student-journey__panel').each(function() {
     $(this).height(maxHeight);
    });
  }

  const init = () => {
    if (!$('.student-journey__button').length) {
      return;
    }

    if ($(window).width() >= 1025) {
      $(window).on('load', heightInit);
    }

    $('.student-journey__button').on('click keydown', studentJourneyToggle);
  };

  return {
    init,
  };
})();

export default studentJourney;
