import stickybits from 'stickybits';

const pathwayAccordion = (() => {
  function togglePathwayAccordion() {
    if ($(window).width() < 700) {
      const scrollpos = $(this)
        .closest('.pathway-list__item__top')
        .offset().top;

      $('html, body').animate({scrollTop: scrollpos});
    }
    $(this)
      .closest('.pathway-list__item__top')
      .toggleClass('expanded');
    $(this)
      .closest('.pathway-list__item')
      .find('.pathway-list__panel')
      .slideToggle();

    if (
      $(this)
        .closest('.pathway-list__item__top')
        .hasClass('expanded')
    ) {
      $(this).text('Close');
      $(this).attr('aria-expanded', 'true');
    } else {
      $(this).text('Quick View');
      $(this).attr('aria-expanded', 'false');
    }
  }
  const init = () => {
    if (!$('.pathway-list__quick-view').length) {
      return;
    }

    stickybits('.pathway-list__item__top');

    $('.pathway-list__quick-view').attr('aria-expanded', 'false');
    $('.pathway-list__quick-view').click(togglePathwayAccordion);
  };

  return {
    init,
  };
})();

export default pathwayAccordion;
