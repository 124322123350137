/**
 * Carousel display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if carousel present
 */

const carousel = (() => {
  // Render carousel
  function renderCarousel() {
    $('.carousel__images').slick({
      asNavFor: '.carousel__captions',
      fade: true,
      dots: true,
    });
    $('.carousel__captions').slick({
      asNavFor: '.carousel__images',
      fade: true,
      arrows: false,
      dots: false,
    });
  }

  const init = () => {
    if (!$('.carousel').length) {
      return;
    }

    renderCarousel();
  };

  return {
    init,
  };
})();

export default carousel;
