import Instafeed from 'instafeed.js';

const instagramLoader = (() => {
  const feed = new Instafeed({
    get: 'user',
    userId: '2201357474',
    clientId: '3b7569ccd2224b58b1c2f1a1806fe871',
    accessToken: '2201357474.3b7569c.35ad556042464496bef53d68d13b8378',
    sortBy: 'most-recent',
    limit: 3,
    template:
      '<div class="instagram__tile column__col"><a class="instagram__link" href="{{link}}" target="_blank" style="background-image: url({{image}})">{{link}}</a></div>',
    resolution: 'standard_resolution',
  });

  const init = () => {
    if (!$('#instafeed').length) {
      return;
    }
    feed.run();
  };

  return {
    init,
  };
})();

export default instagramLoader;
