/**
 * Pathway Carousel display object interactions and accessiblity
 * @param {*} $
 * @returns {init}
 */

import 'slick-carousel';

const pathwayCarousel = (() => {
  const pathwayCarouselSettings = {
    dots: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    draggable: false,
    swipe: true,
    adaptiveHeight: true,
    customPaging: (slider, i) => {
      return $('<button type="button" />').text(
        $(slider.$slides[i])
          .find('.slide-title')
          .text(),
      );
    },
  };

  function slickInit() {
    $('.pathway-carousel__slides').slick(pathwayCarouselSettings);
  }

  function playVideo() {
    $(this)
      .next()
      .addClass('show-vid');
    $(this)
      .next()[0]
      .play();
  }

  const init = () => {
    if (!$('.pathway-carousel__slides').length) {
      return;
    }
    $(window).on('load', slickInit());

    $('.pathway-carousel__play-video').on('click', playVideo);
  };

  return {
    init,
  };
})();

export default pathwayCarousel;
