const catalogProgram = (() => {
  const init = () => {
    if (!$('#program-list__table').length) {
      return;
    }

    // eslint-disable-next-line global-require
    Window.DataTable = require('datatables.net')();

    function appendButton() {
      $('.catalog-program-list__reset').appendTo('#program-list__table_filter');
    }
    const table = $('#program-list__table')
      .on('init.dt', appendButton)
      .DataTable({
        paging: false,
        language: {
          search: 'Search Within List',
          searchPlaceholder: 'Type here',
        },
      });

    function clearTable() {
      table.search('').draw();
    }

    $('.catalog-program-list__reset').click(clearTable);

    function scrollToTop(e) {
      const offset = $('.dataTables_filter').offset().top;
      $('html,body').animate(
        { scrollTop: offset - $('header').outerHeight() },
        300,
      );
      $('.dataTables_filter input')
        .first()
        .focus();
      e.preventDefault();
    }

    $('.catalog-program-list__top').click(scrollToTop);
  };

  return {
    init,
  };
})();

export default catalogProgram;