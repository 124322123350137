/**
 * Homepage Pathway display object interactions and accessiblity
 * @param {*} $
 * @returns {init}
 */

import 'slick-carousel';

const homepagePathways = (() => {
  const homepagePathwaysSettings = {
    dots: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    draggable: false,
    swipe: true,
  };

  function slickInit() {
    $('.homepage-pathways__image-carousel').slick(homepagePathwaysSettings);
  }

  function scrollToTop() {
    $('header').css('top', '-100%');

    setTimeout(() => {
      $('header').css('top', '');
      $('body').removeClass('stickyHeader');
    }, 1000);

    $('html, body').animate({
      scrollTop: $('.homepage-pathways__tabs').offset().top - 130,
    });
    $('.homepage-pathways__tab')
      .eq(0)
      .focus();
  }

  function homepagePathwaysToggle(e) {
    $('.homepage-pathways__panels').show();
    const tab = $($(this).attr('href'));
    tab
      .find('.homepage-pathways__image-carousel')
      .slick('unslick')
      .slick(homepagePathwaysSettings);
    $('.homepage-pathways__panel').hide();
    tab.fadeIn(300);
    $('html, body').animate({
      scrollTop: $('.homepage-pathways__panels').offset().top - 100,
    });

    $('.homepage-pathways__image-carousel:visible .slick-prev').focus();
    e.preventDefault();
  }

  const init = () => {
    if (!$('.homepage-pathways__tab').length) {
      return;
    }

    $('.homepage-pathways__tab').on('click', homepagePathwaysToggle);

    $('.homepage-pathways__button').on('click', scrollToTop);

    $(window).on('load', slickInit());
  };

  return {
    init,
  };
})();

export default homepagePathways;
