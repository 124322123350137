/**
 * Get Started display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if get started object is present
 */

const startedTabs = (() => {
  function startedTabsToggle(e) {
    $('.get-started__panels').show();
    const tab = $($(this).attr('href'));
    $('.get-started__panel').hide();
    tab.fadeIn();
    $('html, body').animate({
      scrollTop: $('.get-started__panels').offset().top - 100,
    });
    $('.get-started__text:visible')
      .find('a:eq(0)')
      .focus();
    e.preventDefault();
    e.preventDefault();
  }

  function scrollToTop() {
    $('header').css('top', '-100%');

    setTimeout(() => {
      $('header').css('top', '');
      $('body').removeClass('stickyHeader');
    }, 1000);

    $('html, body').animate(
      {
        scrollTop: $('.get-started__tabs').offset().top - 140,
      },
      () => {
        setTimeout(() => {
          $('body').removeClass('stickyHeader');
        }, 300);
      },
    );

    $('.get-started__tab')
      .eq(0)
      .focus();
  }

  function enlargeCards() {
    // eslint-disable-next-line func-names
    $('.get-started__tab').each(function() {
      if (
        $(window).scrollTop() >
        $(this).offset().top - $(window).height() / 2
      ) {
        $(this).addClass('animate');
        $(this)
          .prev()
          .removeClass('animate');
        $(this)
          .next()
          .removeClass('animate');
      }
    });
  }

  const init = () => {
    if (!$('.get-started__tab').length) {
      return;
    }

    $('.get-started__button').on('click', scrollToTop);

    $(window).on('scroll', enlargeCards);

    $('.get-started__tab').on('click', startedTabsToggle);
  };

  return {
    init,
  };
})();

export default startedTabs;
