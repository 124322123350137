const tableMobile = (() => {
  function tableAttrs() {
    // cycles through each td
    const $td = $(this);
    // finds index.
    const index = $td.index();

    // finds the html of the th whose index is the same as the index of the td
    const $th = $td
      .closest('table')
      .find('th')
      .eq(index);
    // creates an element whose html is the appropiate heading
    $td.attr('data-th', $th.text());

    if ($td.is(':last-child')) {
      $td.attr('data-last-child', 'true');
    }
  }

  function TableRowGenerate() {
    if ($(this).attr('data-last-child')) {
      $(this)
        .closest('tbody')
        .append(
          `<tr class='small-only last'><th scope='row'>${$(this).data(
            'th',
          )}</th><td>${$(this).html()}</td></tr>`,
        );
    } else {
      $(this)
        .closest('tbody')
        .append(
          `<tr class='small-only'><th scope='row'>${$(this).data(
            'th',
          )}</th><td>${$(this).html()}</td></tr>`,
        );
    }
  }

  const init = () => {
    if (!$('table').length) {
      return;
    }
    $('.wysiwyg table td').each(tableAttrs);
    $('.wysiwyg table td').each(TableRowGenerate);
  };

  return {
    init,
  };
})();

export default tableMobile;
